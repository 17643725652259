<template>
  <div class="content b50" v-wechat-title="$route.meta.title">
    <!-- <van-pull-refresh v-model="isLoading" success-text="刷新成功" @refresh="onRefresh" > -->
      <van-row type="flex" class="content-row p16">
        <van-col class="content-item">
          <van-image round width="65px"  fit="cover" :src="wechatUserInfo.wxAvatar"/>
          <div class="item-right">
          </div>
        </van-col>
        <van-col>
          <div class="item-right-title">{{wechatUserInfo.wxNickname}}</div>
        </van-col>
      </van-row>
      <learning-records/>
    <!-- </van-pull-refresh> -->
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import LearningRecords from '@/components/LearningRecords'
import { mapState } from 'vuex'
// import { POST } from '@/common/request'

// const api= {
//   queryPageUser: '/xkcwebApi/srClassification/querySrClassificationTree.htm',
//   queryUser: "/xkcwebApi/srMoveUser/querySrMoveUserPageList.htm"
// }
export default {
  name: 'AccountNumber',
  components: {
    Footer,
    LearningRecords
  },
  data() {
    return {
      isLoading: false,
    }
  },
   computed: {
    ...mapState({
      wechatUserInfo: state => state.wechatuser.wechatUserInfo
    }),
  },
  methods: {
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  background: linear-gradient(rgba(211,241,255,33%), rgba(211,241,255,0%));
}
.content-row {
  display: flex;
  justify-content: flex-start;
  padding-top: 20px;
}
.content-item {
  // display: flex;/
  // align-items: center;
  // padding-left: 5px;
  /deep/ .van-image--round {
    border-radius: 20%;
  }
}
  .item-right {
    margin: 10px 0 10px 10px;
  }
  .item-right-title {
    font-size: 20px;
    font-weight: 600;
    color: #373858;
    padding: 5px 0 0 15px;
  }
  .item-right-tel {
    display: flex;
    align-items: center;
    font-size: 10px;
    color: #8989A6;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 10px;
  }
  .item-svg {
    display: flex;
    height: 20px;
    line-height: 20px;
    margin: 0 5px 0 0;
  }
.right-info {
  display: flex;
  justify-content: end;
  margin-top: 22px;
}
.van-button--round {
    border-radius: 25%;
}
</style>