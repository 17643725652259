<template>
  <div>
    <h3 class="content p16">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        class="svgIcn"
      >
        <path
          d="M3 5C3 4.44772 3.44772 4 4 4H10.6154C11.1677 4 11.6154 4.44772 11.6154 5V19C11.6154 19.5523 11.1677 20 10.6154 20H4C3.44771 20 3 19.5523 3 19V5Z"
          fill="#6AC0FF"
        />
        <path
          d="M21.4615 5C21.4615 4.44772 21.0138 4 20.4615 4L13.8462 4C13.2939 4 12.8462 4.44772 12.8462 5L12.8462 19C12.8462 19.5523 13.2939 20 13.8462 20L20.4615 20C21.0138 20 21.4615 19.5523 21.4615 19L21.4615 5Z"
          fill="#6AC0FF"
        />
        <path
          d="M18.3847 4C18.3847 4 16.263 4 15.9231 4C15.5833 4 15.3077 4.28319 15.3077 4.63252V8.76445C15.3076 8.82287 15.3387 8.87663 15.3888 8.90426C15.4389 8.9319 15.4997 8.92891 15.547 8.89649L17.1539 7.79511L18.7608 8.89649C18.8081 8.92891 18.8689 8.9319 18.919 8.90426C18.9691 8.87663 19.0002 8.82287 19 8.76445V4.63252C19 4.28319 18.7245 4 18.3847 4Z"
          fill="#FDFCFB"
        />
      </svg>
      <span>学习记录</span>
    </h3>
    <van-tabs
      v-model="active"
      :ellipsis="false"
      title-active-color="#333333"
      color="#23B8FF"
      line-width="16px"
      background="rgba(211,241,255,10%)"
      class="content-tabs"
      @click="onTabsClick"
    >
      <van-tab
        :title="item.title"
        v-for="(item,index) in labelArr"
        :key="index"
        class="title-item"
      />
    </van-tabs>

    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      :immediate-check="false"
      @load="onLoad"
    >
      <van-row type="flex" justify="flex-start" gutter="16" class="content-item p16">
        <van-col v-model="loading" span="12" v-for="item in gridArr" :key="item.id" style>
          <router-link :to="'/details/'+item.curriculumId">
            <div style="height: 120px; overflow: hidden; border-radius: 10px;">
              <img
                v-if="item.curriculumImage"
                :src="`http://114.116.236.37:9001/${item.curriculumImage}`"
                style="height: 100%;"
              />
              <div
                v-else
                style="text-align: center; line-height: 100px; height: 100%; border: 1px solid #f5e5e58f; width: 100%;"
              >暂无图片</div>
            </div>
            <h4 class="van-ellipsis course-title">{{ item.curriculumTitle }}</h4>
            <div class="row-item">
              <span class="teach-title">{{ item.curriculumAuthor }}</span>
              <span class="browse-title">
                <van-icon name="eye" color="rgba(51,51,51,36%)" />
                {{ item.curriculumViews }}
              </span>
            </div>
          </router-link>
        </van-col>
      </van-row>
    </van-list>
  </div>
</template>

<script>
import { POST } from "@/common/request";
const api = {
  querySrClassificationTree: "/xkcshowApi/show/querySrClassificationTree.htm",
  querySrRecord: "/xkcshowApi/show/querySrRecord.htm"
};
export default {
  data() {
    return {
      active: 0,
      labelArr: [],
      labelId: "0",
      gridArr: [],
      pageData: {
        page: 1,
        size: 20,
        total: null
      },
      loading: false,
      finished: false
    };
  },
  mounted() {
    this.postClassificationTree();
    this.getCurriculum("0");
  },
  methods: {
    onLoad() {
      this.pageData.page++;
      this.getCurriculum(this.labelId);
    },
    onTabsClick(name) {
      this.gridArr = [];
      this.labelId = this.labelArr[name].id;
      this.pageData = {
        page: 1,
        size: 20,
        total: 0
      };
      this.getCurriculum(this.labelArr[name].id);
    },
    getCurriculum(cassId) {
      POST(api.querySrRecord, {
        menuId: cassId === "0" ? null : cassId,
        moveUserId: JSON.parse(localStorage.getItem("wechatUserInfo")).value
          .userId,
        // orderBys: "updateTime",
        // orderDir: "desc",
        page: this.pageData.page,
        size: this.pageData.size
      })
        .then(res => {
          if (res.code === 200) {
            if (res.code === 200) {
              let rows = res.data; //请求返回当页的列表
              this.loading = false;
              this.pageData.total = res.total;
              if (rows == null || rows.length === 0) {
                // 加载结束
                this.finished = true;
                return;
              }

              // 将新数据与老数据进行合并
              this.gridArr = this.gridArr.concat(rows);

              //如果列表数据条数>=总条数，不再触发滚动加载
              if (this.gridArr.length >= this.pageData.total) {
                this.finished = true;
              }
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    postClassificationTree() {
      POST(api.querySrClassificationTree)
        .then(res => {
          if (res.code === 200 && res.data.length !== 0) {
            this.labelArr = [
              {
                id: "0",
                title: "全部"
              },
              ...res.data
            ];
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  height: 40px;
  display: flex;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 40px;
  margin-top: 10px;
  color: #333333;
  .svgIcn {
    height: 40px;
    line-height: 40px;
    margin-right: 5px;
  }
}
.content-tabs {
  // margin-top: 12px;
  /deep/ .van-nav-bar__title {
    font-weight: 700;
    color: #333333;
  }
  .title-item {
    justify-content: space-between;
    font-size: 14px;
    color: #666666;
  }
  /deep/ .van-tab--active {
    font-size: 16px;
    font-weight: 600;
  }
}
.content-item {
  margin-top: 10px;
}
.row-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 6px 0 17px 0;
}
.course-title {
  display: flex;
  justify-content: start;
  // padding: 15px 15px 10px 0;
  margin-top: 12px;
  font-size: 16px;
  color: #30334c;
}
.teach-title {
  color: #8989a6;
  font-size: 10px;
}
.browse-title {
  width: 50%;
  height: 20px;
  line-height: 20px;
  color: #8989a6;
  font-size: 10px;
  border-radius: 10px;
  text-align: right;
}
</style>